.eventhead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.eventcard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 850px) {
    .eventcard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.eventtext {
    padding: 55px;
    font-size: 1.2rem;
    text-align: center;
}

.card-custom {
    background-color: wheat;
}

.dark-mode .card-custom {
    background-color: black;
}