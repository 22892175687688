.errorhead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.errortext {
    font-size: 1.5em;
    font-weight: 600;
    color: goldenrod;
}