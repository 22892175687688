        .sidelinksec {
            text-decoration: none;
            /* color: white; */
        }
        
        .techhivelogomini {
            width: 150px;
        }
        
        .sidelogout:hover {
            cursor: pointer;
        }
        
        .active {
            /* background-color: white; */
            opacity: 1;
            font-size: 1.2rem;
        }
        
        .inactive {
            opacity: 0.7;
        }
        /* .activeClicked:h {
    color: rgb(255, 221, 127);
} */