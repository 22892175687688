/* .testdiv {
    background-color: aqua;
    width: 80vw;
} */

.card-custom {
    background-color: wheat;
}

.dark-mode .card-custom {
    background-color: black;
}

.divright {
    display: 'flex';
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    /* border: 2px solid white;
    position: relative;
    align-content: center; */
}

.coursescontainerright {
    width: 100%;
}

.coursecard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}