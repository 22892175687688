/* body {
    color: rgb(43, 190, 38);
} */

.sponsorintro {
    padding: 55px;
    font-size: 1.2rem;
}

.sponsorintro2 {
    padding: 55px;
    font-size: 1.2em;
    /* background-color: rgb(44, 43, 42);
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 5%; */
}

.techhiveaim {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.sponsortext {
    font-size: 1.2em;
    padding-left: 20px;
}

.sponsortextlist {
    font-size: 1.1em;
}

hr.sponsorline {
    position: relative;
    top: 20px;
    border: none;
    height: 12px;
    background: goldenrod;
    margin-bottom: 50px;
}

.sponsor-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.sponsor-hold {
    width: 200px;
    height: 200px;
    border-radius: 40px;
    padding: 10px;
    background-color: white;
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor-holdlight {
    width: 200px;
    height: 200px;
    border-radius: 50px;
    padding: 10px;
    background-color: rgba(230, 190, 12, 0.89);
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor-img {
    width: 90%;
    height: auto;
    border-radius: 30px;
}

@media only screen and (max-width: 1400px) {}