.resetpasswordform {}

.resetformsection {
    padding: 45px;
}

.resetheader {
    color: goldenrod;
    font-size: 2.3em;
    font-weight: 900;
}