.aboutsection {
    padding: 55px;
    margin: 20px;
}

@media screen and (max-width: 850px) {
    .aboutsection {
        padding: 25px;
        margin: 20px;
    }
}