.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 3em;
    cursor: pointer;
    color: goldenrod;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    z-index: 1000;
}

.scroll-to-top:hover {
    opacity: 1;
}