.hackathonlist {
    box-shadow: 5px 5px goldenrod;
    ;
    max-width: 400px;
    list-style: none;
}

.hackathonul {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 550px) {
    .hackathonul {
        display: flex;
        flex-direction: column;
    }
}

.card-custom {
    background-color: wheat;
}

.dark-mode .card-custom {
    background-color: black;
}

.hackathonhead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.scholarshiphead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}


/* .modal-body-custom {
    background-color: wheat;
} */

.dark-mode .modal-body-custom {
    background-color: #343a40;
}

.hackathoncard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.scholarshipcard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modalsponsor {
    width: 40%;
}