/* .loginform {
    border: 2px solid goldenrod;
} */

.loginformsection {
    padding: 45px;
}

.loginheader {
    color: goldenrod;
    font-size: 2.3em;
    font-weight: 900;
}

.errormessage {
    color: red;
}