.profiledivright {
    display: 'flex';
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.profilecontainerright {
    width: 100%;
    justify-content: center;
}

.profileheader {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}