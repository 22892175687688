.techhivelogo {
    width: 250px;
}


/* .container {
    min-height: 300vh;
} */


/* .darkswitch {
    border: 1px solid black;
} */

.darkswitch:hover {
    cursor: pointer;
}

.navlinksec {
    text-decoration: none;
    color: black;
}