


*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.hero-img{
    border-radius: 30px;
    background: radial-gradient(#2d3059 .75px, #000000 0.75px) padding-box,
    linear-gradient(145deg, transparent 15%,#225018, #fcfc02) border-box;
    border: 5px solid transparent;
}


/* p {
    font-size: 16px
} */

.card-custom {
    background-color: wheat;
}

.techhiveaim {
    /* font-size: 3em; */
    font-weight: 900;
}

.homesection .homeintro {
    font-size: 1.5rem;
}

.aimrow {
    justify-content: center;
}

.whatwedo {
    font-weight: 900;
    color: goldenrod;
}


/* .homesection {
    height: 100vh;
} */


/* .homeintroimg {
    background-image: url("/public/images/community1.jpg");
    background-size: cover;
} */