.social-icon {
    font-size: 24px;
    text-decoration: none;
}

.social-icon:hover {
    text-decoration: none;
}

.designedby {
    text-decoration: none;
    color: #d3a81b;
}

.designedbycol {
    font-size: 12px;
}

.social-icon .bi-linkedin {
    color: #0077b5;
}

.social-icon .bi-twitter {
    color: #1da1f2;
}

.social-icon .bi-facebook {
    color: #1877f2;
}

.social-icon .bi-youtube {
    color: #ff0000;
}

.social-icon .bi-whatsapp {
    color: #6cad6f;
}

.social-icon .bi-instagram {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .footer-text {
    color: #ffffff;
}

.dark-mode .designedby {
    color: #ffffff;
}

.dark-mode .designedby:hover {
    color: #cccccc;
}

.dark-mode .footer {
    background-color: rgb(12, 12, 12);
    color: rgb(221, 233, 233);
}

.footer {
    background-color: rgb(92, 88, 88);
    color: rgb(221, 233, 233);
}

.footerlinks {
    text-decoration: none;
    color: rgb(221, 233, 233);
}

.footerlinks:hover {
    color: rgb(221, 233, 233);
}

.techhivelogo {
    width: 250px;
}