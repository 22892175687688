.allbloglist {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

@media screen and (max-width: 850px) {
    .allbloglist {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.bloglistimage {
    width: 500px;
    height: 200px;
}

.bloglistlink {
    text-decoration: none;
    color: goldenrod;
}