.tossection {
    padding: 55px;
    margin: 50px;
}

@media screen and (max-width: 850px) {
    .tossection {
        padding: 25px;
        margin: 20px;
    }
}

.tossection>h1 {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.tossection>h3,
.tossection>p>b {
    color: goldenrod;
    font-weight: 700;
}