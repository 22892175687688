.classeshead {
    font-weight: 900;
    color: goldenrod;
}

.classestalk {
    color: goldenrod;
    font-weight: 600;
}

.classesheadjoin {
    font-size: 2.3rem;
    font-weight: 900;
    color: goldenrod;
}

.dark-table-header {
    background-color: #343a40;
    color: white;
}

.dark-table-row {
    background-color: #4f565e;
    color: white;
}

.coursecards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

@media screen and (max-width: 850px) {
    .coursecards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.classessection {
    padding-left: 55px;
    padding-right: 55px;
    margin-left: 50px;
    margin-right: 50px;
}

@media screen and (max-width: 850px) {
    .classessection {
        padding: 25px;
        margin: 20px;
    }
}