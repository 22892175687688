.elitehead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.eliteimage {
    width: 300px;
    height: 250px;
    /* border: 2px solid gold; */
    /* border-radius: 50%; */
    object-fit: cover;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    /* width: 250px;
    height: 250px;
    border: 2px solid gold;
    border-radius: 50%;
    object-fit: cover; */
}


/* .elitedisplay {
    display: flex;
    flex-direction: row-reverse;
} */

.compact-list {
    margin-bottom: 0;
}

.compact-list li {
    margin-right: 5px;
    margin-bottom: 5px;
}


/* .hexagon {
    position: relative;
    width: 300px;
    height: 173.21px;
    background-color: #64C7CC;
    margin: 86.60px 0;
}

.hexagon:before,
.hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
}

.hexagon:before {
    bottom: 100%;
    border-bottom: 86.60px solid #64C7CC;
}

.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 86.60px solid #64C7CC;
} */