@font-face {
    font-family: Mark;
    src: url(/public/fonts/Mark-Pro-Heavy.ttf) format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Marklight;
    src: url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap') format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

body {
    background-color: #ffff;
    color: #0f0f0f;
    /* font-family: "Marklight" !important; */
}

.modal-body-custom {
    background-color: wheat;
}

.dark-mode .modal-body-custom {
    background-color: #343a40;
}

.dark-mode {
    background-color: rgb(39, 39, 39);
    color: rgb(221, 233, 233);
}

.mark-font {
    font-family: "Mark" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Mark" !important;
}


/* .dark-mode .btn-primary {
    background-color: #444;
    border-color: #444;
} */