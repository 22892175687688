/* .communityside {
    position: relative;
    .divmain {
        display: 'flex';
        height: 100vh;
    }
} */

.communityrow {
    overflow-x: hidden;
    display: flex;
    min-height: 100vh;
}

.communitymain {
    flex-grow: 1;
    overflow-x: hidden;
}

.communtyheader {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.communitycontainerright {
    width: 100%;
    text-align: center;
    margin: 5px;
}

.communitydivright {
    display: 'flex';
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    /* border: 2px solid white; */
    /* position: relative;
    align-content: center; */
}

.communitylinkimg {
    width: 100px;
}


/* .m-3 {
    border: 2px solid white;
    width: 90%;
    margin: 6px;
    padding: 7px;
} */