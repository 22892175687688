.card-custom {
    display: flex;
    flex-direction: column;
}

.card-custom .card-body {
    flex: 1 0 auto;
}


/* .card-body {
    max-height: 400px;
    overflow: auto;
} */