.contactsection {
    padding: 55px;
    margin: 20px;
}

@media screen and (max-width: 850px) {
    .contactsection {
        padding: 25px;
        margin: 20px;
    }
}

.contacthead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.contacttext {
    font-size: 1.2em;
}

.contactemail {
    color: goldenrod;
    font-weight: bold;
}