.founder-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 10vw;
}

.founder-card {
    display: flex;
    flex-direction: column;
    border: solid 2px rgba(255, 255, 255, 0.368);
    border-radius: 20px;
    padding: 50px 50px;
    margin: 20px 50px;
    width: 40%;
    overflow: hidden;
}

.founder-cardlight {
    display: flex;
    flex-direction: column;
    border: solid 2px rgba(20, 19, 19, 0.368);
    border-radius: 20px;
    padding: 50px 50px;
    margin: 20px 50px;
    width: 40%;
    overflow: hidden;
}


/* .founder-dark-mode {
    background-color: #f5f4ef;
} */

.center-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.center-div {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.right-div {
    width: 100%;
    display: flex;
    justify-content: right;
}

.left-div {
    width: 100%;
    display: flex;
    justify-content: left;
}

.occupation {
    background-color: rgba(163, 141, 12, 0.209)!important;
    color: rgb(255, 217, 0);
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 20px 0px;
    width: 80%
}

.occupationlight {
    background-color: rgba(149, 211, 218, 0.291)!important;
    color: rgb(129, 111, 6);
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 20px 0px;
    width: 80%
}

.achievements {
    font-size: 10px;
}

.tech-div {
    flex-wrap: wrap;
}

.techs {
    background-color: rgba(149, 211, 218, 0.291)!important;
    color: rgb(100, 255, 218);
    font-weight: 500;
    border-radius: 15px;
    height: 25px;
    padding-top: 6px;
    padding-left: 11px;
    padding-right: 11px;
    margin: 2px 5px;
}

.techslight {
    background-color: rgba(149, 211, 218, 0.291)!important;
    color: rgb(10, 17, 15);
    font-weight: 500;
    border-radius: 15px;
    height: 25px;
    padding-top: 6px;
    padding-left: 11px;
    padding-right: 11px;
    margin: 2px 5px;
}

.profile-pic {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background: radial-gradient(#2d3059 .75px, #000000 0.75px) padding-box, linear-gradient(145deg, transparent 15%, #49404a, #40c9ff) border-box;
    border: 5px solid transparent;
}

.name {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 30px;
    font-weight: 300;
}

.site-btn {
    width: 100%;
    margin-top: 20px;
    color: "#000000"
}

@media only screen and (max-width: 1400px) {
    .founder-card {
        margin: 20px 50px;
    }
    .founder-container {
        padding: 0px 5vw;
    }
    .founder-card {
        width: 40%
    }
}

@media only screen and (max-width: 1000px) {
    .founder-card {
        width: 100%
    }
}