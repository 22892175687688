/* .joinsecimg {
    background-image: url("/public/images/techhiveai.png");
    background-size: cover;
} */

.joinhead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.joindiv {
    padding: 45px;
    margin: 30px;
}

@media screen and (max-width: 850px) {
    .joindiv {
        padding: 5px;
        margin: 5px;
    }
}

.joindiv>section>h4 {
    font-size: 1.3rem;
    color: goldenrod;
    font-weight: 900;
}