.careerhead {
    font-size: 2.3em;
    font-weight: 900;
    color: goldenrod;
}

.dark-table-header {
    background-color: #343a40;
    color: #cc9706;
}

.dark-table-row {
    background-color: #4f565e;
    color: #cc9706;
}